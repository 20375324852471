<template>
  <!-- Content -->
  <b-row class="justify-content-md-center">
    <b-col cols="12" md="12" style="max-width: 820px">
      <!-- ** Ticket ** -->
      <b-card v-if="item" no-body>
        <b-card-body class="no-padding">
          <div class="price-header">
            <h2>{{ item.price / 100 }} €</h2>
          </div>
          <div class="p-2">
            <!-- Ticket Name -->
            <h3>
              {{ item.name[currentLocale] || Object.values(item.name)[0] }}
            </h3>
            <!-- Ticket info -->
            <b-card-text v-if="item.createdAt" class="text-muted mt-1 mb-2">
              {{ timestampToNow(item.createdAt) }}
            </b-card-text>
            <hr>
            <b-col
              v-if="eventItem.createdByCommunity"
              cols="12"
              class="pt-75 ml-0 pl-0"
            >
              <!-- Owner -->
              <b-avatar
                size="38"
                :src="getImageSrc(eventItem.createdByCommunity.logoURL)"
                :alt="
                  translate(eventItem.createdByCommunity.name) || translate(eventItem.name)
                "
                class="user-avatar"
              />
              <span v-if="eventItem.createdByCommunity" class="mb-1 ml-1">
                {{ translate(eventItem.createdByCommunity.name) }}
                <span
                  v-if="translate(eventItem.createdByCommunity.headline)"
                  class="ml-50 text-muted"
                >
                  {{ translate(eventItem.createdByCommunity.headline) }}
                </span>
              </span>
            </b-col>

            <!-- Description -->
            <b-row>
              <b-col cols="12">
                <b-card-text v-if="translate(item.description)" class="mt-2 mb-1">
                  <span v-html="getText(translate(item.description))" />
                </b-card-text>
              </b-col>
            </b-row>
            <b-link
              v-if="item.price !== 0"
              :to="{ name: 'orderPurchase', params: { id: id, type: 'ticket' } }"
            >
              <b-button variant="primary" class="mt-2">
                Comprar
              </b-button>
            </b-link>
            <b-button
              v-else
              variant="primary"
              class="mt-2"
              @click="checkPrice"
            >
              Comprar
            </b-button>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
  BAvatar,
  BFormTextarea,
  BImg,
  BBadge,
  BModal,
  BMedia,
  BMediaAside,
  BFormFile,
  BMediaBody,
  BFormGroup,
  BLink,
} from 'bootstrap-vue';
import UserAvatar from '@core/components/user/UserAvatar.vue';
import { quillEditor } from 'vue-quill-editor';
import moment from 'moment';
import Service from '@/config/service-identifiers';
import { getImageResource } from '@/@core/utils/image-utils';
import CustomfieldsModal from '@/@core/components/modal/CustomfieldsModal.vue';
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import Vue from 'vue';
import ForumPlaceholder from '@/assets/images/placeholders/light/forums.svg';

export default {
  name: 'TicketDetails',
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BCardText,
    UserAvatar,
    quillEditor,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BAvatar,
    BFormTextarea,
    ClassifiersModal,
    CustomfieldsModal,
    BImg,
    BBadge,
    BModal,
    BMedia,
    BMediaAside,
    BFormFile,
    BMediaBody,
    BFormGroup,
    BLink,
  },
  data() {
    return {
      isLoading: true,
      editorOption: { placeholder: 'Escriba su idea al reto' },
      form: { name: '', description: '' },
      lastLoadedPageAnswers: 1,
      lastLoadedPageComments: 1,
      answers: { data: [] },
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      newComment: '',
      selectedAnswer: {},
      imageSrc: null,
      imageForum: null,
      forum: { name: '', description: '' },
    };
  },
  computed: {
    id() {
      return this.$route.params.ticketId;
    },
    eventId() {
      return this.$route.params.id;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    item() {
      return this.$store.getters.item('ticket', this.id);
    },
    Placeholder() {
      return Placeholder;
    },
    eventItem() {
      return this.$store.getters.item('events', this.eventId);
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.fetchEvent();
    this.updateBreadcrumbs();
  },
  methods: {
    async checkPrice() {
      if (this.item.price === 0) {
        await this.$store.$service[Service.BackendClient].post(
          '/subscribeEvent',
          { eventKey: this.eventId, attendance: 2, communityKey: this.$store.getters.currentCollective },
        );
        this.$router.push({
          name: 'event',
          params: {
            id: this.eventId,
            communityId: this.$route.params.communityId,
          },
        });
      }
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async fetchEvent() {
      const response = await this.$store.dispatch('getItems', {
        itemType: 'events',
        requestConfig: {
          eventKeys: this.eventId,
          count: 1,
          offset: 0,
        },
      });
      this.isLoading = false;
    },
    // TODO: Remove this workaround. The BE should always return objects in descriptions
    getText(value) {
      if (!value) return;
      if (typeof value === 'object') { return value[this.currentLocale] || Object.values(value)[0]; }

      const object = JSON.parse(value);
      return object[this.currentLocale] || Object.values(object)[0];
    },
    timestampToNow(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).fromNow();
    },
    async scrollBottom() {
      const element = document.getElementById('commentForm');
      element.scrollIntoView({ behavior: 'smooth' });
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    translate(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field,
      );
    },
    timestampToTime(timestamp) {
      return moment(timestamp).format('DD-MM-YYYY');
    },
    async fetchData() {
      await this.$store.dispatch('getItemsPayments', {
        itemType: 'ticket',
        ticketKey: this.id,
      });
      this.isLoading = false;
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: 'forums.breadcrumb-text.forums-list',
          to: { name: 'forums' },
        },
        {
          text:
            this.item?.name[this.currentLocale]
            || Object.values(this.item?.name)[0]
              ? this.item?.name[this.currentLocale]
                || Object.values(this.item?.name)[0]
              : 'forums.breadcrumb-text.forum-details',
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
  },
};
</script>
<style scoped>
.price-header {
  width: 100%;
  background: #28aab8;
  background-color: #abe9cd;
  text-align: center !important;
  background-image: linear-gradient(315deg, #abe9cd 0%, #3eadcf 74%);
  padding-top: 100px;
  padding-bottom: 75px;
}

.price-header * {
  color: #fff;
  text-align: center !important;
  font-weight: bold;
}
</style>
